@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clickable {
    @apply cursor-pointer;
  }
}

/**
* Limit height of @isheepdog/operator-search results list element
*/
.react-autosuggest__suggestions-container--open,
.operator-search-results {
  @apply overflow-y-scroll;
  @apply max-h-[70vh];
  @apply overflow-x-hidden;
}
